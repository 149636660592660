import
{
  createAction, props
} from "@ngrx/store";

import
{
  Option, SearchParameter
} from "shared-lib";
import
{
  DocumentUpdate,
  Protocol
} from "../../shared/models";


// Define the action types


export const loadOptions = createAction(
  "[Options] Load Options",
  props<{ fieldName: string; takeLimited: boolean }>()
);

export const updateOptions = createAction(
  "[Options] Update Options",
  props<{ fieldName: string; options: Option[]; }>()
);
export const loadFilteredOptions = createAction(
  "[Options] Load Filtered Options",
  props<{ fieldName: string; searchParams: SearchParameter[]; }>()
);

// TODO: to remove any.
export const loadFilteredOptionsFailure = createAction(
  "[Options] Load Filtered Options Failure",
  props<{ error: any; }>()
);


export const loadOptionsSuccess = createAction(
  "[Options] Load Options Success",
  props<{ fieldName: string; options: Option[]; }>()
);

export const loadOptionsFailure = createAction(
  "[Options] Load Options Failure",
  props<{ error: any; }>()
);


export const loadConnectedOptions = createAction(
  "[Options] Load Connected Options",
  props<{ fieldName: string; connected: SearchParameter[]; }>()
);

export const updateConnectedOptions = createAction(
  "[Options] Update Connected Options",
  props<{ fieldName: string; options: Option[]; }>()
);


export const loadConnectedOptionsSuccess = createAction(
  "[Options] Load Connected Options Success",
  props<{ fieldName: string; options: Option[]; }>()
);

export const loadConnectedOptionsFailure = createAction(
  "[Options] Load Connected Options Failure",
  props<{ error: any; }>()
);


export const insertProtocol = createAction(
  "[Protocol] Insert",
  props<{ protocol: Protocol; documents?: DocumentUpdate[]; }>()
);

export const insertProtocolSuccess = createAction(
  "[Protocol] Insert Success",
  props<{ result: boolean | null; }>()
);

export const insertProtocolFailure = createAction(
  "[Protocol] Insert Failure",
  props<{ error: any; }>()
);

// Check If Intervention Approval Exist
export const checkIfInterventionApprovalExist = createAction(
  "[Protocol] Check If Valid Intervention Approval Exists",
  props<{ studentId: string}>()
);

export const checkIfInterventionApprovalExistSuccess = createAction(
  `[Protocol] Check If Valid Intervention Approval Exists Success`,
  props<{ interventionApprovalId: string | null }>()
);

export const checkIfInterventionApprovalExistFailure = createAction(
  `[Protocol] Check If Valid Intervention Approval Exists Failure`,
  props<{ error: string }>()
);


export const loadCategoryOptions = createAction(
  "[Options] Load Category Options",
  props<{ fieldName: string; searchParams: SearchParameter[]; categoryId: string; }>()
);

export const loadCategoryOptionsSuccess = createAction(
  "[Options] Load Category Options Success",
  props<{ fieldName: string; options: Option[]; categoryId: string; }>()
);

// TODO: to remove any.
export const loadCategoryOptionsFailure = createAction(
  "[Options] Load Category Options Failure",
  props<{ error: any; }>()
);